/**

 This file bundles all the variable overrides of the individual components of react-toolbox.
 For more information see: https://github.com/react-toolbox/react-toolbox/tree/master#theming

 */
.AddMemberInputTheme__bar--291Ti {
  display: none; }

.AddMemberInputTheme__input--2LcNy {
  padding: 1.2rem 0; }

.AddMemberInputTheme__inputElement--3-mvA {
  border: 0;
  padding-left: 1.6rem; }

.AddMemberInputTheme__icon--Yv7Sf {
  top: 0.7rem;
  padding-left: 1.6rem; }

.AddMemberInputTheme__counter--yyuWE {
  display: none; }
