/**

 This file bundles all the variable overrides of the individual components of react-toolbox.
 For more information see: https://github.com/react-toolbox/react-toolbox/tree/master#theming

 */
.Features__features--3nN86 {
  text-align: center;
  background: #2d978d;
  color: white; }
  .Features__features--3nN86 .Features__feature--3EIaL h3 {
    color: white; }
  .Features__features--3nN86 .Features__feature--3EIaL .Features__icon--2LcRh i {
    font-size: 40px;
    color: #164844; }
  .Features__features--3nN86 .Features__feature--3EIaL p {
    margin-bottom: 60px;
    font-size: 16px; }
    @media (min-width: 768px) {
      .Features__features--3nN86 .Features__feature--3EIaL p {
        margin-bottom: 20px; } }
