/**

 This file bundles all the variable overrides of the individual components of react-toolbox.
 For more information see: https://github.com/react-toolbox/react-toolbox/tree/master#theming

 */
.FABMenu__container--38pUi {
  position: fixed;
  bottom: 3rem;
  right: 3rem;
  display: flex;
  flex-direction: column;
  align-items: flex-end; }
