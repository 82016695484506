/**

 This file bundles all the variable overrides of the individual components of react-toolbox.
 For more information see: https://github.com/react-toolbox/react-toolbox/tree/master#theming

 */
.Container__page--2_v1n {
  margin-left: 2rem;
  margin-right: 2rem; }

.Container__main--2iITN {
  padding: 2rem;
  background-color: #34aca1;
  color: white; }
