/**

 This file bundles all the variable overrides of the individual components of react-toolbox.
 For more information see: https://github.com/react-toolbox/react-toolbox/tree/master#theming

 */
.NobtItButtonTheme__button--1reBX {
  transform: scale(1.3);
  box-shadow: none;
  margin-top: 10px; }
