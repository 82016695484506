/**

 This file bundles all the variable overrides of the individual components of react-toolbox.
 For more information see: https://github.com/react-toolbox/react-toolbox/tree/master#theming

 */
.HomeScreen__homeScreen--8BxIq {
  margin: auto;
  background-color: white;
  min-height: 100vh; }

.HomeScreen__overviewContainer--3Fovp {
  background-color: #34aca1;
  padding: 20px 15px 25px 15px;
  color: white;
  text-align: center; }
  .HomeScreen__overviewContainer--3Fovp .HomeScreen__nobtTitle--3uSd2 {
    font-size: xx-large;
    margin-bottom: 10px; }
  .HomeScreen__overviewContainer--3Fovp .HomeScreen__nobtMetadata--360dK {
    font-size: 13px; }
    .HomeScreen__overviewContainer--3Fovp .HomeScreen__nobtMetadata--360dK ul {
      padding: 0;
      list-style: none;
      margin-bottom: 10px; }
      .HomeScreen__overviewContainer--3Fovp .HomeScreen__nobtMetadata--360dK ul li {
        display: inline-block;
        padding-left: 5px;
        margin-right: 10px; }
        .HomeScreen__overviewContainer--3Fovp .HomeScreen__nobtMetadata--360dK ul li div {
          display: flex;
          align-items: center; }
          .HomeScreen__overviewContainer--3Fovp .HomeScreen__nobtMetadata--360dK ul li div [data-react-toolbox='font-icon'] {
            margin-right: 5px;
            font-size: 18px; }
