/**

 This file bundles all the variable overrides of the individual components of react-toolbox.
 For more information see: https://github.com/react-toolbox/react-toolbox/tree/master#theming

 */
.ConfirmationDialog__navigation--19CMJ {
  display: flex;
  justify-content: space-evenly; }
  .ConfirmationDialog__navigation--19CMJ * > {
    width: 100%; }
