/**

 This file bundles all the variable overrides of the individual components of react-toolbox.
 For more information see: https://github.com/react-toolbox/react-toolbox/tree/master#theming

 */
.AddMemberItemInputTheme__bar--3KoOy {
  display: none; }

.AddMemberItemInputTheme__input--2Yedr {
  padding: 1.2rem 0;
  flex: 1; }

.AddMemberItemInputTheme__inputElement--3VV7W {
  border: 0; }

.AddMemberItemInputTheme__icon--Yweyi {
  top: 0.7rem; }

.AddMemberItemInputTheme__counter--2x9DU {
  display: none; }
