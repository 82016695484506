/**

 This file bundles all the variable overrides of the individual components of react-toolbox.
 For more information see: https://github.com/react-toolbox/react-toolbox/tree/master#theming

 */
.FeedItemTheme__itemContentRoot--vtvla {
  overflow: hidden; }

.FeedItemTheme__itemText--37uny {
  overflow: hidden;
  text-overflow: ellipsis; }
