/**

 This file bundles all the variable overrides of the individual components of react-toolbox.
 For more information see: https://github.com/react-toolbox/react-toolbox/tree/master#theming

 */
.Section__section--G5G7U {
  padding: 1.5rem;
  margin-bottom: 1rem;
  background-color: white; }

.Section__sectionCaption--ei3ya {
  font-size: inherit;
  font-family: inherit;
  font-weight: bold;
  margin: 0.5rem 0; }

.Section__sectionGroup--d4-zd {
  background-color: rgba(255, 255, 255, 0.5);
  border-radius: 0.2rem;
  margin-bottom: 1.5rem;
  color: black;
  overflow: hidden; }
