/**

 This file bundles all the variable overrides of the individual components of react-toolbox.
 For more information see: https://github.com/react-toolbox/react-toolbox/tree/master#theming

 */
.UnknownNobt__container--1ljQw {
  display: flex;
  width: 100%;
  height: 90vh;
  align-items: center;
  justify-content: center;
  flex-direction: column; }

.UnknownNobt__icon--_X2uq > [data-react-toolbox] {
  font-size: xx-large; }
