/**

 This file bundles all the variable overrides of the individual components of react-toolbox.
 For more information see: https://github.com/react-toolbox/react-toolbox/tree/master#theming

 */
.Logo__Logo--38wHj {
  font-family: Comfortaa; }
  .Logo__Logo--38wHj h1 {
    margin: 0;
    font-size: large;
    color: #34aca1; }
    .Logo__Logo--38wHj h1 a {
      color: inherit;
      text-decoration: none; }
