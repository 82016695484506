/**

 This file bundles all the variable overrides of the individual components of react-toolbox.
 For more information see: https://github.com/react-toolbox/react-toolbox/tree/master#theming

 */
.Avatar__avatar--3TmQW {
  text-align: center;
  color: white;
  font-weight: bold;
  border-radius: 50%;
  vertical-align: middle; }
  .Avatar__avatar--3TmQW.Avatar__small--25Dca {
    font-size: 1.2rem;
    line-height: 2.4rem;
    width: 2.4rem;
    height: 2.4rem; }
  .Avatar__avatar--3TmQW.Avatar__medium--2iwjn {
    font-size: 1.5rem;
    line-height: 3rem;
    width: 3rem;
    height: 3rem; }
  .Avatar__avatar--3TmQW.Avatar__large--jbvvS {
    font-size: 1.8rem;
    line-height: 3.6rem;
    width: 3.6rem;
    height: 3.6rem; }
