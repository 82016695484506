/**

 This file bundles all the variable overrides of the individual components of react-toolbox.
 For more information see: https://github.com/react-toolbox/react-toolbox/tree/master#theming

 */
.BasicInformationForm__continueButtonContainer--3J_6t {
  margin-top: 4rem;
  text-align: center; }

.BasicInformationForm__form--mY6eB {
  margin: 3rem 0; }

.BasicInformationForm__introductionTextContainer--2uo9A {
  font-size: larger; }

.BasicInformationForm__formLine--1Rd7t .BasicInformationForm__description--1f--T {
  margin-top: -1rem;
  font-size: small; }

.BasicInformationForm__introductionTextContainer--2uo9A,
.BasicInformationForm__description--1f--T {
  color: #9c9c9c; }
