/**

 This file bundles all the variable overrides of the individual components of react-toolbox.
 For more information see: https://github.com/react-toolbox/react-toolbox/tree/master#theming

 */
.MenuButtonTheme__button--2_JrU {
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.14), 0 4px 8px rgba(0, 0, 0, 0.28);
  transform: none;
  transition: transform 0.225s cubic-bezier(0, 0, 0.2, 1); }
  .MenuButtonTheme__button--2_JrU.MenuButtonTheme__expanded--JdFRF {
    transform: rotate(45deg);
    transition: transform 0.195s cubic-bezier(0.4, 0, 1, 1); }
