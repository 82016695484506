/**

 This file bundles all the variable overrides of the individual components of react-toolbox.
 For more information see: https://github.com/react-toolbox/react-toolbox/tree/master#theming

 */
.EmptyNobtPlaceholder__container--2xThH {
  color: #979797;
  text-align: center;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  top: 40%; }
  .EmptyNobtPlaceholder__container--2xThH .EmptyNobtPlaceholder__icon--72AO6 {
    margin-bottom: 2rem;
    height: 30%;
    max-height: 160px;
    background: no-repeat center;
    background-size: contain; }
  .EmptyNobtPlaceholder__container--2xThH .EmptyNobtPlaceholder__topLabel--2-aYm {
    font-size: 30px;
    margin-bottom: 1rem; }
  .EmptyNobtPlaceholder__container--2xThH .EmptyNobtPlaceholder__linkLabel--3ipCN {
    font-size: 20px; }
    .EmptyNobtPlaceholder__container--2xThH .EmptyNobtPlaceholder__linkLabel--3ipCN a {
      color: #2d978d;
      text-decoration: none; }
