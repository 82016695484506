/**

 This file bundles all the variable overrides of the individual components of react-toolbox.
 For more information see: https://github.com/react-toolbox/react-toolbox/tree/master#theming

 */
.Header__header--3-pa0 {
  height: 100%;
  padding: 0 !important;
  overflow-y: hidden;
  background-size: cover;
  background-position: center center;
  color: white; }
  .Header__header--3-pa0 .Header__content--2MUBy {
    text-align: center;
    padding: 80px 0 50px;
    position: relative; }
    .Header__header--3-pa0 .Header__content--2MUBy > div {
      position: relative; }
    .Header__header--3-pa0 .Header__content--2MUBy h1.Header__handwritten--2FjbG {
      font-family: Courgette, cursive;
      margin-bottom: 35px; }
    @media (min-width: 768px) {
      .Header__header--3-pa0 .Header__content--2MUBy {
        text-align: left;
        padding: 0;
        margin-right: 20px;
        height: 100vh; }
        .Header__header--3-pa0 .Header__content--2MUBy > div {
          max-width: none;
          text-align: right;
          margin: 0;
          right: 0;
          position: absolute;
          top: 50%;
          transform: translateY(-50%); }
        .Header__header--3-pa0 .Header__content--2MUBy h1 {
          font-size: 35px; } }
    @media (min-width: 992px) {
      .Header__header--3-pa0 .Header__content--2MUBy {
        font-size: 40px; } }
  .Header__header--3-pa0 .Header__mockup--Xd0eI {
    position: relative;
    width: 100%;
    padding-bottom: 201.76%; }
    .Header__header--3-pa0 .Header__mockup--Xd0eI > .Header__device--1fLbw {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      width: 100%;
      height: 100%;
      background-size: 100% 100%;
      background-repeat: no-repeat; }
      @media (min-width: 992px) {
        .Header__header--3-pa0 .Header__mockup--Xd0eI > .Header__device--1fLbw {
          margin: 180px auto 0; } }
      @media (min-width: 768px) {
        .Header__header--3-pa0 .Header__mockup--Xd0eI > .Header__device--1fLbw {
          margin: 200px auto 0; } }
      .Header__header--3-pa0 .Header__mockup--Xd0eI > .Header__device--1fLbw > .Header__screen--sVm8R {
        position: absolute;
        top: 11.58%;
        bottom: 11.58%;
        left: 5.97%;
        right: 5.97%;
        overflow: hidden; }
        .Header__header--3-pa0 .Header__mockup--Xd0eI > .Header__device--1fLbw > .Header__screen--sVm8R > img {
          max-width: 100%;
          display: block;
          height: auto; }
  .Header__header--3-pa0 .Header__affix--2Yhku {
    z-index: 1000;
    padding: 10px 0;
    width: 100%;
    position: fixed !important;
    top: 0 !important; }
    .Header__header--3-pa0 .Header__affix--2Yhku.Header__affixScroll--25VRN {
      background-color: #353535; }
      .Header__header--3-pa0 .Header__affix--2Yhku.Header__affixScroll--25VRN .Header__logo--2aEEb h1,
      .Header__header--3-pa0 .Header__affix--2Yhku.Header__affixScroll--25VRN .Header__navbar--2ysoa ul li a {
        color: white; }
    .Header__header--3-pa0 .Header__affix--2Yhku .Header__logo--2aEEb h1 {
      margin-top: 10px;
      color: rgba(255, 255, 255, 0.7); }
    .Header__header--3-pa0 .Header__affix--2Yhku .Header__navbar--2ysoa {
      text-align: right;
      display: none; }
      @media (min-width: 768px) {
        .Header__header--3-pa0 .Header__affix--2Yhku .Header__navbar--2ysoa {
          display: block; } }
      .Header__header--3-pa0 .Header__affix--2Yhku .Header__navbar--2ysoa ul {
        list-style-type: none;
        margin: 0;
        padding: 0;
        overflow: hidden; }
        .Header__header--3-pa0 .Header__affix--2Yhku .Header__navbar--2ysoa ul li {
          display: inline-block; }
          .Header__header--3-pa0 .Header__affix--2Yhku .Header__navbar--2ysoa ul li a {
            display: block;
            color: rgba(255, 255, 255, 0.7);
            text-align: center;
            padding: 10px;
            margin: 0 5px;
            text-decoration: none; }
            .Header__header--3-pa0 .Header__affix--2Yhku .Header__navbar--2ysoa ul li a i {
              margin-right: 3px; }
