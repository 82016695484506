/**

 This file bundles all the variable overrides of the individual components of react-toolbox.
 For more information see: https://github.com/react-toolbox/react-toolbox/tree/master#theming

 */
.Button__rightIcon--37E9X {
  float: right;
  margin-left: 0.6rem;
  margin-right: 0 !important; }

.Button__button--3_Ozh {
  font-weight: bold;
  font-family: inherit; }
