/**

 This file bundles all the variable overrides of the individual components of react-toolbox.
 For more information see: https://github.com/react-toolbox/react-toolbox/tree/master#theming

 */
.LoadingNobt__loader--2TSzM {
  width: 100%;
  height: 80vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column; }
  .LoadingNobt__loader--2TSzM .LoadingNobt__separator--17Gkz {
    flex-grow: 1; }
  .LoadingNobt__loader--2TSzM .LoadingNobt__progressBar--m3tdZ {
    flex-grow: 3; }
