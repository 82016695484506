/**

 This file bundles all the variable overrides of the individual components of react-toolbox.
 For more information see: https://github.com/react-toolbox/react-toolbox/tree/master#theming

 */
.Label__label--363QH {
  opacity: 0;
  transition: opacity 0.225s cubic-bezier(0, 0, 0.2, 1);
  white-space: nowrap;
  margin-right: 1rem;
  color: white;
  background-color: #2d978d;
  border-radius: 0.2rem;
  padding: 0.3rem 0.6rem; }
  .Label__label--363QH.Label__disabled--3QYWd {
    color: rgba(0, 0, 0, 0.26);
    background-color: rgba(0, 0, 0, 0.12); }
  .Label__label--363QH.Label__expanded--3TvwI {
    z-index: 1;
    opacity: 100;
    transition: opacity 0.195s cubic-bezier(0.4, 0, 1, 1); }
