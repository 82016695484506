/**

 This file bundles all the variable overrides of the individual components of react-toolbox.
 For more information see: https://github.com/react-toolbox/react-toolbox/tree/master#theming

 */
.DeletedFeedItemTheme__itemContentRoot--i7-47 {
  overflow: hidden;
  opacity: 0.3; }

.DeletedFeedItemTheme__itemText--1Wna8 {
  overflow: hidden;
  text-overflow: ellipsis;
  text-decoration: line-through; }
