/**

 This file bundles all the variable overrides of the individual components of react-toolbox.
 For more information see: https://github.com/react-toolbox/react-toolbox/tree/master#theming

 */
.About__About--2KCFv {
  text-align: center; }
  .About__About--2KCFv .About__description--3AXfW {
    padding: 20px 0;
    max-width: 500px;
    margin: auto; }
  .About__About--2KCFv .About__examples--cyqQp {
    padding: 5px 0 30px; }
    @media only percy {
      .About__About--2KCFv .About__examples--cyqQp {
        visibility: hidden; } }
  .About__About--2KCFv .About__Steps--1ep9N {
    display: none;
    margin-top: 70px;
    text-align: center; }
    .About__About--2KCFv .About__Steps--1ep9N .About__Counter--1BLjy {
      font-size: 20px;
      color: #2d978d; }
      .About__About--2KCFv .About__Steps--1ep9N .About__Counter--1BLjy span {
        border-radius: 50%;
        border: 1px solid #2d978d;
        width: 40px;
        height: 40px;
        line-height: 37px;
        display: block;
        margin: 0 auto 10px; }
    .About__About--2KCFv .About__Steps--1ep9N h4 {
      margin: 20px 0; }
    .About__About--2KCFv .About__Steps--1ep9N img {
      width: 80%;
      border-radius: 10px;
      margin: auto;
      border: 3px solid grey; }
    @media (min-width: 768px) {
      .About__About--2KCFv .About__Steps--1ep9N {
        display: block; } }
