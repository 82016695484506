/**

 This file bundles all the variable overrides of the individual components of react-toolbox.
 For more information see: https://github.com/react-toolbox/react-toolbox/tree/master#theming

 */
/**
 * This hides the corresponding item.
 * Note that we are selecting the item that this mixin is applied to (&-selector).
 */
.Item__item--3nsyN {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 1rem;
  transition: transform 0.225s cubic-bezier(0, 0, 0.2, 1); }
  .Item__item--3nsyN:nth-child(0) {
    transform: translateY(0%)translateY(0rem); }
  .Item__item--3nsyN:nth-child(1) {
    transform: translateY(100%)translateY(1rem); }
  .Item__item--3nsyN:nth-child(2) {
    transform: translateY(200%)translateY(2rem); }
  .Item__item--3nsyN:nth-child(3) {
    transform: translateY(300%)translateY(3rem); }
  .Item__item--3nsyN:nth-child(4) {
    transform: translateY(400%)translateY(4rem); }
  .Item__item--3nsyN.Item__expanded--32XYa {
    transform: none;
    transition: transform 0.195s cubic-bezier(0.4, 0, 1, 1); }
