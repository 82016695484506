/**

 This file bundles all the variable overrides of the individual components of react-toolbox.
 For more information see: https://github.com/react-toolbox/react-toolbox/tree/master#theming

 */
.WizardContainer__wizardContainer--25kHR {
  max-width: 480px;
  margin: 0 auto;
  padding: 2rem;
  background-color: white;
  min-height: 100vh; }
  @media only screen and (min-width: 768px) {
    .WizardContainer__wizardContainer--25kHR {
      padding-top: 10rem; } }
