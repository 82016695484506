/**

 This file bundles all the variable overrides of the individual components of react-toolbox.
 For more information see: https://github.com/react-toolbox/react-toolbox/tree/master#theming

 */
.BrandedAppBarTheme__title--3FmcC {
  font-family: Comfortaa;
  text-align: left !important;
  text-transform: none !important;
  font-size: 1.8rem;
  margin: 0; }
  .BrandedAppBarTheme__title--3FmcC a {
    cursor: pointer; }
  .BrandedAppBarTheme__title--3FmcC a:hover,
  .BrandedAppBarTheme__title--3FmcC a:focus,
  .BrandedAppBarTheme__title--3FmcC a:visited {
    text-decoration: none; }
