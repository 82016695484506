/**

 This file bundles all the variable overrides of the individual components of react-toolbox.
 For more information see: https://github.com/react-toolbox/react-toolbox/tree/master#theming

 */
a.StartButton__button--OAIXI {
  border: 1px solid white; }

a.StartButton__activeButton--2JJdX {
  border: 1px solid #2d978d;
  background-color: #2d978d; }

a.StartButton__activeButton--2JJdX,
a.StartButton__button--OAIXI {
  border-radius: 25px;
  color: white;
  display: inline-block;
  font-size: 11px;
  padding: 15px 45px;
  text-align: center;
  text-transform: uppercase;
  touch-action: manipulation;
  letter-spacing: 2px;
  transition: background 500ms ease; }
  a.StartButton__activeButton--2JJdX:hover, a.StartButton__activeButton--2JJdX:focus, a.StartButton__activeButton--2JJdX:visited, a.StartButton__activeButton--2JJdX:link,
  a.StartButton__button--OAIXI:hover,
  a.StartButton__button--OAIXI:focus,
  a.StartButton__button--OAIXI:visited,
  a.StartButton__button--OAIXI:link {
    color: #fff;
    text-decoration: none; }
  a.StartButton__activeButton--2JJdX:hover, a.StartButton__activeButton--2JJdX:focus,
  a.StartButton__button--OAIXI:hover,
  a.StartButton__button--OAIXI:focus {
    background-color: #2d978d;
    border-color: #2d978d; }
