/**

 This file bundles all the variable overrides of the individual components of react-toolbox.
 For more information see: https://github.com/react-toolbox/react-toolbox/tree/master#theming

 */
.AmountTheme__positive--33Cgn {
  color: green; }

.AmountTheme__negative--PlOht {
  color: red; }
