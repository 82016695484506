/**

 This file bundles all the variable overrides of the individual components of react-toolbox.
 For more information see: https://github.com/react-toolbox/react-toolbox/tree/master#theming

 */
html {
  box-sizing: border-box; }

html,
body,
#app__root--957ii {
  margin: 0;
  padding: 0;
  height: 100%; }

*,
*:before,
*:after {
  box-sizing: inherit; }

section,
header {
  padding: 1rem 0; }

ul,
ol {
  margin-bottom: 0 !important; }

fieldset {
  border: none;
  padding: 0;
  margin: 0; }

p {
  margin: 0; }
