/**

 This file bundles all the variable overrides of the individual components of react-toolbox.
 For more information see: https://github.com/react-toolbox/react-toolbox/tree/master#theming

 */
.Box__box--1MIZc {
  border: 1px solid #e3e3e3;
  border-radius: 0.2rem;
  margin-bottom: 1rem;
  margin-top: 1rem; }
