/**

 This file bundles all the variable overrides of the individual components of react-toolbox.
 For more information see: https://github.com/react-toolbox/react-toolbox/tree/master#theming

 */
.Footer__footer--1HuPR {
  background: #eee;
  padding: 30px;
  text-align: center; }
  .Footer__footer--1HuPR .Footer__links--14wDV {
    margin-top: 20px;
    font-size: 12px; }
    .Footer__footer--1HuPR .Footer__links--14wDV ul {
      list-style-type: none;
      margin: 0;
      padding: 0; }
      .Footer__footer--1HuPR .Footer__links--14wDV ul li {
        display: inline-block; }
        .Footer__footer--1HuPR .Footer__links--14wDV ul li a {
          margin: 10px; }
          .Footer__footer--1HuPR .Footer__links--14wDV ul li a i {
            margin-right: 2px; }
          .Footer__footer--1HuPR .Footer__links--14wDV ul li a:hover, .Footer__footer--1HuPR .Footer__links--14wDV ul li a:focus, .Footer__footer--1HuPR .Footer__links--14wDV ul li a:visited, .Footer__footer--1HuPR .Footer__links--14wDV ul li a:link {
            color: inherit;
            text-decoration: none; }
  @media (min-width: 768px) {
    .Footer__footer--1HuPR {
      text-align: left; }
      .Footer__footer--1HuPR .Footer__links--14wDV {
        text-align: right;
        margin-top: 0; } }
