/**

 This file bundles all the variable overrides of the individual components of react-toolbox.
 For more information see: https://github.com/react-toolbox/react-toolbox/tree/master#theming

 */
.ListItemTheme__left--2sNWQ [data-react-toolbox='font-icon'] {
  color: rgba(0, 0, 0, 0.26); }

.ListItemTheme__item--2sxra {
  padding-right: 0.5rem; }
