/**

 This file bundles all the variable overrides of the individual components of react-toolbox.
 For more information see: https://github.com/react-toolbox/react-toolbox/tree/master#theming

 */
.DoneScreen__topContainer--1JZ4X {
  text-align: center;
  margin: 4rem 0 3rem; }
  .DoneScreen__topContainer--1JZ4X .DoneScreen__checkContainer--nXi4V > i {
    color: #2d978d;
    font-size: 8rem; }

.DoneScreen__linkSection--6gP5d {
  padding-top: 2rem; }
  .DoneScreen__linkSection--6gP5d .DoneScreen__nobtLinkContainer--2E6Xc {
    background-color: #e8e8e8;
    border-radius: 0.2rem;
    padding: 1rem;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center; }
    .DoneScreen__linkSection--6gP5d .DoneScreen__nobtLinkContainer--2E6Xc a,
    .DoneScreen__linkSection--6gP5d .DoneScreen__nobtLinkContainer--2E6Xc a:visited,
    .DoneScreen__linkSection--6gP5d .DoneScreen__nobtLinkContainer--2E6Xc a:hover,
    .DoneScreen__linkSection--6gP5d .DoneScreen__nobtLinkContainer--2E6Xc a:focus,
    .DoneScreen__linkSection--6gP5d .DoneScreen__nobtLinkContainer--2E6Xc a:active,
    .DoneScreen__linkSection--6gP5d .DoneScreen__nobtLinkContainer--2E6Xc a:link {
      font-size: medium;
      color: #2d978d;
      text-decoration: none; }
      @media only screen and (min-width: 768px) {
        .DoneScreen__linkSection--6gP5d .DoneScreen__nobtLinkContainer--2E6Xc a,
        .DoneScreen__linkSection--6gP5d .DoneScreen__nobtLinkContainer--2E6Xc a:visited,
        .DoneScreen__linkSection--6gP5d .DoneScreen__nobtLinkContainer--2E6Xc a:hover,
        .DoneScreen__linkSection--6gP5d .DoneScreen__nobtLinkContainer--2E6Xc a:focus,
        .DoneScreen__linkSection--6gP5d .DoneScreen__nobtLinkContainer--2E6Xc a:active,
        .DoneScreen__linkSection--6gP5d .DoneScreen__nobtLinkContainer--2E6Xc a:link {
          font-size: large; } }
  .DoneScreen__linkSection--6gP5d .DoneScreen__note--1q9zo {
    margin-top: 2rem;
    font-size: smaller;
    text-align: center;
    color: #9c9c9c; }

.DoneScreen__copyButtonContainer--1VUA4 {
  text-align: center;
  margin-top: 2rem; }
