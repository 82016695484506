/**

 This file bundles all the variable overrides of the individual components of react-toolbox.
 For more information see: https://github.com/react-toolbox/react-toolbox/tree/master#theming

 */
.LandingPage__container--2D4DL {
  height: 100%; }
  .LandingPage__container--2D4DL p {
    font-size: 17px; }
  .LandingPage__container--2D4DL section {
    padding: 100px 0 !important; }
