/**

 This file bundles all the variable overrides of the individual components of react-toolbox.
 For more information see: https://github.com/react-toolbox/react-toolbox/tree/master#theming

 */
.AppBarTheme__appBar--2qbEV {
  color: white; }

.AppBarTheme__title--2z4tm {
  text-align: center;
  text-transform: uppercase;
  margin-top: 1rem;
  margin-bottom: 1rem; }
