/**

 This file bundles all the variable overrides of the individual components of react-toolbox.
 For more information see: https://github.com/react-toolbox/react-toolbox/tree/master#theming

 */
.Overlay__overlay--3c5w4 {
  background-color: rgba(227, 227, 227, 0);
  transition: background-color 0.225s cubic-bezier(0, 0, 0.2, 1);
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%; }
  .Overlay__overlay--3c5w4.Overlay__active--1xnV_ {
    background-color: rgba(227, 227, 227, 0.9);
    transition: background-color 0.195s cubic-bezier(0.4, 0, 1, 1); }
