/**

 This file bundles all the variable overrides of the individual components of react-toolbox.
 For more information see: https://github.com/react-toolbox/react-toolbox/tree/master#theming

 */
.Team__Team--2NVN8 {
  text-align: center; }
  .Team__Team--2NVN8 .Team__CoffeeInfo--2sF7L {
    margin: 30px 0; }
  .Team__Team--2NVN8 .Team__teamImages--22W3N {
    margin-top: 20px; }
    .Team__Team--2NVN8 .Team__teamImages--22W3N .Team__imageFrame--15et3 {
      height: 200px;
      width: 200px;
      display: inline-block;
      margin: 20px;
      position: relative; }
      .Team__Team--2NVN8 .Team__teamImages--22W3N .Team__imageFrame--15et3 .Team__imageDetailsWrapper--1i2Xa {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        display: none; }
        .Team__Team--2NVN8 .Team__teamImages--22W3N .Team__imageFrame--15et3 .Team__imageDetailsWrapper--1i2Xa .Team__imageDetails--36Ae1 {
          position: absolute;
          top: 0;
          right: 0;
          bottom: 0;
          left: 0;
          background-color: rgba(0, 0, 0, 0.8);
          display: flex;
          align-items: center;
          justify-content: center; }
          .Team__Team--2NVN8 .Team__teamImages--22W3N .Team__imageFrame--15et3 .Team__imageDetailsWrapper--1i2Xa .Team__imageDetails--36Ae1 h3 {
            color: white;
            font-family: Courgette, cursive;
            font-size: xx-large; }
          .Team__Team--2NVN8 .Team__teamImages--22W3N .Team__imageFrame--15et3 .Team__imageDetailsWrapper--1i2Xa .Team__imageDetails--36Ae1 ul {
            list-style: none;
            margin: 0;
            padding: 0; }
            .Team__Team--2NVN8 .Team__teamImages--22W3N .Team__imageFrame--15et3 .Team__imageDetailsWrapper--1i2Xa .Team__imageDetails--36Ae1 ul li {
              display: inline;
              padding: 0 10px; }
              .Team__Team--2NVN8 .Team__teamImages--22W3N .Team__imageFrame--15et3 .Team__imageDetailsWrapper--1i2Xa .Team__imageDetails--36Ae1 ul li a {
                color: white; }

#Team__teamMember1--HeEor {
  background-image: url(/thomas_single_811e.png); }

#Team__teamMember2--1fg3N {
  background-image: url(/david_single_9a9d.png); }

#Team__teamMember3--34Ztn {
  background-image: url(/matthias_single_36a9.png); }

@media only screen and (min-width: 768px) {
  #Team__teamMember1--HeEor {
    background-image: url(/thomas_61a7.png); }
  #Team__teamMember2--1fg3N {
    background-image: url(/david_a3e6.png); }
  #Team__teamMember3--34Ztn {
    background-image: url(/matthias_1470.png); } }

@media only percy {
  #Team__teamMember1--HeEor {
    background-image: url(/thomas_single_811e.png) !important; }
  #Team__teamMember2--1fg3N {
    background-image: url(/david_single_9a9d.png) !important; }
  #Team__teamMember3--34Ztn {
    background-image: url(/matthias_single_36a9.png) !important; } }
