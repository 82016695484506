/**

 This file bundles all the variable overrides of the individual components of react-toolbox.
 For more information see: https://github.com/react-toolbox/react-toolbox/tree/master#theming

 */
.Items__items--19IwF {
  display: flex;
  flex-direction: column-reverse;
  align-items: flex-end; }
