/**

 This file bundles all the variable overrides of the individual components of react-toolbox.
 For more information see: https://github.com/react-toolbox/react-toolbox/tree/master#theming

 */
.ContinueButtonTheme__button--1Hz_a {
  transform: scale(1.3);
  font-weight: bold;
  box-shadow: none !important;
  margin-bottom: 0.4rem;
  margin-top: 0.4rem; }
