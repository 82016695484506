/**

 This file bundles all the variable overrides of the individual components of react-toolbox.
 For more information see: https://github.com/react-toolbox/react-toolbox/tree/master#theming

 */
.text__heading--1HRuG {
  font-size: 3rem;
  margin: 2rem 0 1rem;
  font-weight: bold; }

.text__subHeading--15Oyv {
  opacity: 0.5;
  font-size: 2rem;
  margin: 0 0 2rem; }

.text__caption--aR10K {
  font-size: inherit;
  font-weight: bold;
  margin: 0.5rem 0; }

.text__title--3vHno {
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-size: 2.4rem;
  font-weight: 400;
  line-height: 3.2rem;
  -moz-osx-font-smoothing: grayscale;
  margin: 0;
  margin-top: 1rem;
  margin-bottom: 0.5rem;
  padding-top: 1rem; }

.text__subTitle--2lUpO {
  color: #757575;
  padding-top: 1rem; }

.text__legend--3tEnO {
  font-size: 1.2rem;
  line-height: 2rem;
  color: grey;
  margin: 0; }
