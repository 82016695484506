/**

 This file bundles all the variable overrides of the individual components of react-toolbox.
 For more information see: https://github.com/react-toolbox/react-toolbox/tree/master#theming

 */
.AddMembersForm__introductionTextContainer--2TSup {
  font-size: larger; }

.AddMembersForm__createNobtButtonContainer--QmWuC {
  text-align: center; }

.AddMembersForm__note--2oiqD {
  font-size: smaller;
  text-align: center; }
  .AddMembersForm__note--2oiqD p {
    color: #9c9c9c; }

.AddMembersForm__introductionTextContainer--2TSup {
  color: #9c9c9c; }
