/**

 This file bundles all the variable overrides of the individual components of react-toolbox.
 For more information see: https://github.com/react-toolbox/react-toolbox/tree/master#theming

 */
.AppLayout__layout--3DyTd {
  background-color: #e3e3e3;
  min-height: 100vh; }
  @media only screen and (min-width: 768px) {
    .AppLayout__layout--3DyTd {
      padding: 5rem 0; } }

.AppLayout__container--3df5h {
  background-color: white; }
  @media only screen and (min-width: 768px) {
    .AppLayout__container--3df5h {
      margin: auto;
      max-width: 768px;
      border-radius: 0.2rem;
      overflow: hidden;
      box-shadow: 0 0 4px rgba(0, 0, 0, 0.14), 0 4px 8px rgba(0, 0, 0, 0.28); } }
