/**

 This file bundles all the variable overrides of the individual components of react-toolbox.
 For more information see: https://github.com/react-toolbox/react-toolbox/tree/master#theming

 */
.CurrencySelect__label--1fzOb {
  display: flex !important;
  align-items: center; }

.CurrencySelect__flag--3lsf0 {
  margin-right: 0.5rem; }

.Select {
  margin-bottom: 1.5rem; }
