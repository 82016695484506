/**

 This file bundles all the variable overrides of the individual components of react-toolbox.
 For more information see: https://github.com/react-toolbox/react-toolbox/tree/master#theming

 */
.SelectorItem__item--2ryIo {
  padding: 0 0.5rem; }

.SelectorItem__link--1rLpQ {
  display: block;
  border-bottom: 1px solid #e3e3e3; }
  .SelectorItem__link--1rLpQ:focus,
  .SelectorItem__link--1rLpQ a:hover,
  .SelectorItem__link--1rLpQ a:active,
  .SelectorItem__link--1rLpQ a:visited {
    outline: none;
    color: inherit;
    text-decoration: none; }
  .SelectorItem__link--1rLpQ:focus {
    background-color: #e3e3e3; }
